$(document).ready(function() {
    if ($(window).width() > 1000) {
        $(document).on('mouseenter', '.vidWrapper', function() {
            $(this).find('.content').addClass('h200');
            $(this).find('.js_assdeselip').css('display', 'block');
            $(this).find('video').toggle();

            $(this).find('.content').css("height", $(this).height() + 'px')
            $(this).find('.content').css("bottom", '0px')
                //$(this).find('img').toggle();

        });
        $(document).on('mouseleave', '.vidWrapper', function() {
            $(this).find('.content').removeClass('h200');
            $(this).find('.js_assdeselip').css('display', 'none');
            $(this).find('video').toggle();
            $(this).find('.content').css("height", '0px')
            $(this).find('.content').css("bottom", '-58px')

            //$(this).find('img').toggle();
        });
    }
    $('#sidebar').mCustomScrollbar({
        theme: 'minimal'
    });

    $('#dismiss, .overlay').on('click', function() {
        $('#sidebar').removeClass('active');
        $('.overlay').removeClass('active');
    });

    $('#sidebarCollapse').on('click', function() {
        $('#sidebar').addClass('active');
        $('.overlay').addClass('active');
        $('.collapse.in').toggleClass('in');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
    });

    // $('.box-container-1').tooltipster({
    //   interactive: true,
    //   maxWidth: 267
    // });

    $('#lightSlider').lightSlider({
        gallery: true,
        item: 1,
        loop: false,
        slideMargin: 0,
        thumbItem: 24,
        verticalHeight: 400
    });

    $('.mobile-search i').click(function() {
        $('.search-box-mobile').toggle();
    });

    $('.login-controll').click(function() {
        var openDiv = $(this).attr('data-attr');
        $('.form-warper').hide();
        $(openDiv).show();
    });

    $(".tablist ul li a").click(function() {

        $('.tablist ul li').removeClass('active');
        $(this).parent('li').addClass('active');

        var active_div = $(this).attr('dtat-target');
        $(".tabing").hide();
        $('#' + active_div).show();

    });

    $(".seasons-list li").click(function() {
        $(".seasons-list li").removeClass("activeSeasons");
        $(this).addClass("activeSeasons");
    });

});

var popupFunctionObject = (function() {
    return {
        showPopUp: function(myModal) {
            $("#" + myModal).modal({ show: true });
        },
        closePopUp: function(myModal) {
            $("#" + myModal).modal('toggle');
            $('body').removeClass('modal-open');
        }
    }
})(popupFunctionObject || {});

$(document).ready(function() {
    /*** start Owl carosal ***/

    /*** end Owl carosal ***/


    // transperent header

    $(function() {
        $(document).scroll(function() {
            var $nav = $(".transperent-layout .bg-black");
            $nav.toggleClass('add-backcolor', $(this).scrollTop() > $nav.height());
        });
    });
    /*** loadin script ***/

    $('.title-bar ul li a').click(function() {
        $(this)
            .parents('.container')
            .find('.loader-overlay')
            .show();

        //console.log('click');
    });

    /*** End loadin script ***/


});

$(window).on("load", function() {
    /*** Chat  ***/
    window.tiledeskSettings = {
        projectid: "5e162a4c58d7df00175e27ef"
    };
    (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://widget.tiledesk.com/v3/launch.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'tiledesk-jssdk'));
    /*** End ***/
});